@import '@angular/cdk/overlay-prebuilt.css';
@import 'variables';


html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-size: 12px;
    font-family: 'Inter', sans-serif;
    background-color: #EDEEEF;

    // @media (horizontal-viewport-segments: 2) {
        
    // }
}


button.app-btn,
label.app-btn {
    border-radius: 4px;
    color: white;
    background-color: $primary-color;
    height: 39px;
    width: 140px;
    font-size: 14px;
    line-height: 17px;
    border: 0 none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:disabled {
        background-color: $disabled_control_bg_color;
        cursor: not-allowed;

    }

    input[type='file'] {
        display: none;
    }
}

h2.page-title {
    font-size: 40px;
    color: #000;
    // margin-top: 9px;
    // margin-bottom: 33px;
    font-weight: normal;
    text-align: center;
    align-self: center;
    margin: 0;
    // @media (horizontal-viewport-segments: 2) {
    //     padding: 40px;
    //     text-align: center;
    //     display: flex;
    //     align-items: center;
    //     margin: 0;
    // }

    @media all and (max-width: 850px) {
            font-size: 20px;
    }

}

button.icon-btn {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 0 none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    justify-self: end;
}


